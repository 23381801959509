import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "flex flex-col items-center" }
const _hoisted_2 = {
  key: 0,
  class: "w-full text-sm mb-1 font-semibold"
}
const _hoisted_3 = ["placeholder"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("label", _hoisted_2, _toDisplayString(_ctx.label) + " : ", 1))
      : _createCommentVNode("", true),
    _withDirectives(_createElementVNode("textarea", {
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.inputValue) = $event)),
      placeholder: _ctx.placeholder,
      class: "input w-full box border border-gray-400 dark:bg-dark-4"
    }, null, 8, _hoisted_3), [
      [_vModelText, _ctx.inputValue]
    ])
  ]))
}