
import { computed, defineComponent } from "vue";

export default defineComponent({
  props: {
    label: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    value: {
      type: String,
      default: "",
    },
  },
  setup(props, { emit }) {
    const inputValue = computed({
      get: () => props.value,
      set: (value) => emit("update:value", value),
    });

    return {
      inputValue,
    };
  },
});
